import SuspenseFallback from '@/components/SuspenseFallback';
import {
  CONTEST_PAGE,
  DAILY_CHECK_IN_PAGE,
  GET_OFFER_DETAILS_PAGE,
  HOME_PAGE,
  REFERRALS_PAGE,
  SPIN_WIN_PAGE,
  WALLET_PAGE,
} from '@/constants/routes.const';
import useService from '@/hooks/useService';
import { User } from '@/model/Users';
import { AndroidService } from '@/service/Android';
import { AuthService } from '@/service/AuthService';
import { ReferralService } from '@/service/ReferralService';
import { StorageService } from '@/service/StorageService';
import { UserService } from '@/service/User';
import { useRouter } from 'next/navigation';
import { WalletService } from '@/service/WalletService';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import { firebaseApp } from '@/configs/firebase';
const AuthContext = createContext(null);

export function AuthContextProvider(props) {
  const router = useRouter();
  const [contestEnabled, setContestEnabled] = useState(false);
  const countryCodeApi = useService(UserService.getCountyCode);
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(null);

  async function initializeContestConfig() {
    const userCountry = await countryCodeApi.call();
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 60;
    remoteConfig.defaultConfig = {
      adjoe: false,
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        const contestEnabled = getValue(
          remoteConfig,
          'is_contest_enabled'
        ).asBoolean();

        if (!contestEnabled) return;

        const contestCountry = JSON.parse(
          getValue(remoteConfig, 'contest_filter').asString()
        );

        const includedCountries = contestCountry?.country?.include;
        const excludedCountries = contestCountry?.country?.exclude;

        //global targeting
        if (!includedCountries?.length && !excludedCountries?.length) {
          setContestEnabled(true);
          return;
        }

        if (
          includedCountries?.length &&
          includedCountries.includes(userCountry)
        ) {
          setContestEnabled(true);
          return;
        }

        if (
          excludedCountries?.length &&
          !excludedCountries.includes(userCountry)
        ) {
          setContestEnabled(true);
          return;
        }
      })
      .catch(err => {
        console.log('Error fetching remote config', err);
      });
  }

  useEffect(() => {
    initializeContestConfig();
  }, []);

  const {
    data: user,
    isLoading,
    isLoaded,
    call: getUserDetails,
    error,
  } = useService(UserService.getUser);

  const {
    call: getWalletBalance,
    data: wallet,
    isLoaded: isBalanceLoaded,
    isLoading: isBalanceLoading,
  } = useService(WalletService.getWalletBalance);

  const { data: referralCode, call: getReferralCode } = useService(
    ReferralService.getReferralCode
  );

  const {
    call: profileInit,
    isLoading: isProfileInitLoading,
    isLoaded: isProfileInitLoaded,
  } = useService(UserService.profileInit);
  const { call: storeFcmToken } = useService(UserService.storeFcmToken);

  useEffect(() => {
    if (!StorageService.getAccessToken()) {
      router.push('/login');
    }

    if (StorageService.getAccessToken() && !user) {
      getUserDetails()
        .then(data => {
          Sentry.setUser({
            id: data.userId,
            username: data.fullName,
            email: data.email,
          });
          StorageService.setUserId(data.userId);
          handleNotificationRedirection();

          /**
           * This key is used to encrypt the profile init data
           * backend logic -> md5(md5(userId + phoneNumner))
           */
          let key = data.userId;
          if (data.phoneNumber) {
            key = key + data.phoneNumber;
          }
          callProfileInit(key);
          getWalletBalance(StorageService.getWId());
          getReferralCode();
        })
        .catch(err => {
          AuthService.logout();
          router.push('/login');
        });
    }
  }, []);

  function handleNotificationRedirection() {
    const intentData = AndroidService.getIntentData();
    let notif = intentData.notification;
    if (notif) {
      router.push(getPathFromNotificationType(notif));
    }
  }

  async function callProfileInit(userId) {
    //t-3 day
    var today = new Date();
    var startDate = today.setDate(today.getDate() - 3);

    const usageData = await AndroidService.getAppUsageData({
      startTime: startDate,
      endTime: Date.now(),
    });
    let fcmToken;
    try {
      fcmToken = await AndroidService.getUpdatedFCMToken();
    } catch (error) {}
    if (fcmToken) {
      try {
        await storeFcmToken(fcmToken);
      } catch (error) {
        console.error('Error in storing fcm token', error);
      }
    }
    const payload = {
      adv_id: AndroidService.getDeviceIdentifiers().ga_id,
      p_id: AndroidService.getDeviceIdentifiers().package_id,
      ...AndroidService.getDeviceIdentifiers(),
      current_installed_apps: AndroidService.getInstalledPackages(false),
      usage_data: JSON.parse(usageData) || [],
    };
    const formData = new FormData();
    formData.append(
      'file',
      new Blob([AndroidService.encrypt(userId, JSON.stringify(payload))])
    );
    profileInit(formData).then(data => {
      if (data) {
        User.setProfileId(data.profileId);
        AndroidService.addUserParamsAnalytics('profile_id', data.profileId);
      }
    });
    AndroidService.addUserParamsAnalytics('uid', userId);
  }

  const isAuth = useMemo(() => {
    if (!user || isLoading) return false;
    return true;
  }, [user, isLoading]);

  const refreshUser = () => {
    getUserDetails();
  };

  const refreshWallet = () => {
    getWalletBalance(StorageService.getWId());
  };

  const values = useMemo(() => {
    return {
      authUser: user || null,
      isLoaded: isLoaded || isProfileInitLoaded,
      isLoading: isLoading || isProfileInitLoading,
      isBalanceLoaded,
      isBalanceLoading,
      walletBalance: wallet?.balance?.amount,
      isAuth,
      referralCode: referralCode?.referral_code,
      referralCount: referralCode?.usage_count,
      refreshUser,
      refreshWallet,
      contestEnabled,
      activeSort: sort,
      activeTags: tags,
      setTags,
      setSort,
    };
  }, [
    user,
    isBalanceLoaded,
    isBalanceLoading,
    wallet,
    isAuth,
    isLoading,
    referralCode,
    isProfileInitLoading,
    contestEnabled,
    sort,
    tags,
  ]);

  return (
    <AuthContext.Provider value={values}>
      {!isLoaded ? <AuthLoader /> : props.children}
    </AuthContext.Provider>
  );
}

/**
 * @return {{
 *   authUser: User,
 *   isAuth: boolean,
 *   referralCode: string,
 *   referralCount: number
 *  }}
 *
 */
export default function useAuthContext() {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error('Misconfigured AuthContext');
  }

  return ctx;
}

function AuthLoader() {
  return <SuspenseFallback />;
}

function getPathFromNotificationType(notification) {
  switch (notification.entity_type) {
    case 'daily_reward':
      return DAILY_CHECK_IN_PAGE;
    case 'offer':
      return GET_OFFER_DETAILS_PAGE(notification.entity_id);
    case 'contest':
      return CONTEST_PAGE;
    case 'spin_wheel':
      return SPIN_WIN_PAGE;
    case 'referral':
      return REFERRALS_PAGE;
    case 'wallet':
      return WALLET_PAGE;
    default:
      return HOME_PAGE;
  }
}
